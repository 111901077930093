import React, { useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../common/components/LocalizationProvider';
import {
  Signal,
  GPS,
  Power,
  Battery,
} from '../common/components/CommonObjects';
import { getSignal } from '../common/components/CommonValues';
import CameraDialog from './CameraDialog';

export const verifyInstallation = (position) => {
  if (!position) return false;
  if (!position.attributes.hasOwnProperty('rssi')) return false;
  if (!position.attributes.hasOwnProperty('hdop')) return false;
  if (!position.attributes.hasOwnProperty('power')) return false;

  const signal = getSignal(position.attributes.rssi);
  const { hdop, power } = position.attributes;

  return power > 7 && hdop <= 10 && hdop > 0 && signal >= 2;
};

const InstallPage = () => {
  const t = useTranslation();
  const [imei, setImei] = useState('');
  const [vin, setVin] = useState('');
  const [cameraOpen, setCameraOpen] = useState(false);
  const [fieldToScan, setFieldToScan] = useState(null);
  const [position, setPosition] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/');
  };

  const handleOpenCamera = async (field) => {
    // Attempt to access the camera
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });

    // If successful, open the camera dialog
    if (stream) {
      setFieldToScan(field);
      setCameraOpen(true);
    }
  };

  const handleCaptureResult = (field, result) => {
    if (field === 'imei') setImei(result);
    if (field === 'vin') setVin(result);
  };

  const verifyDevice = async () => {
    try {
      const response = await fetch(`/api/devices/verify?uniqueid=${imei}&vin=${vin}`);
      if (response.ok) {
        const verifiedPosition = await response.json();
        setPosition(verifiedPosition);
        setVerifying(false);
      }
    } catch (e) {
      setVerifying(false);
      throw new Error('Communication Error');
    }
  };

  const handleSubmit = () => {
    setVerifying(true);
    verifyDevice();
  };

  const handleCancelVerification = () => {
    setVerifying(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={3}>
      <Box display="flex" justifyContent="flex-start" width="100%" mb={2}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <TextField
        label={t('deviceIMEI')}
        value={imei}
        onChange={(e) => setImei(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleOpenCamera('imei')} aria-label="Open Camera for IMEI">
              <PhotoCameraIcon />
            </IconButton>
          ),
        }}
      />
      <TextField
        label={t('vehicleVIN')}
        value={vin}
        onChange={(e) => setVin(e.target.value)}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => handleOpenCamera('vin')} aria-label="Open Camera for VIN">
              <PhotoCameraIcon />
            </IconButton>
          ),
        }}
      />
      <Box display="flex" alignItems="center" gap={2} mt={2}>
        {verifying ? (
          <>
            <Button variant="contained" color="primary" disabled>
              {t('sharedVerifying')}
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleCancelVerification}
            >
              {t('sharedCancel')}
            </Button>
          </>
        ) : position ? (
          <Button variant="contained" color={verifyInstallation(position) ? 'success' : 'error'}>
            {t('sharedInstalled')}
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t('sharedVerify')}
          </Button>
        )}
      </Box>
      <CameraDialog
        open={cameraOpen}
        field={fieldToScan}
        onClose={() => setCameraOpen(false)}
        onCapture={handleCaptureResult}
      />
      {position && (
        <Box mt={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" width="100%">
          <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
            {position.attributes.hasOwnProperty('rssi') && <Signal t={t} signal={getSignal(position.attributes.rssi)} />}
            {position.attributes.hasOwnProperty('hdop') && <GPS t={t} hdop={position.attributes.hdop} />}
            {position.attributes.hasOwnProperty('power') && <Power t={t} power={position.attributes.power} />}
            {position.attributes.hasOwnProperty('batteryLevel') && (
              <Battery t={t} batteryLevel={position.attributes.batteryLevel} charging={position.attributes.charge} />
            )}
          </Box>
          <Box mt={2}>
            <Typography variant="body2">
              {`${t('deviceLastUpdate')} ${moment(position.deviceTime).fromNow()}`}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InstallPage;
