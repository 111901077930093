import React, { useRef, useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from '../common/components/LocalizationProvider';

const CameraDialog = ({ open, field, onClose, onCapture }) => {
  const t = useTranslation();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const startCamera = () => {
    setIsProcessing(false); // Set processing to false
    setCapturedImage(null); // Clear the captured image
    navigator.mediaDevices.getUserMedia({
      video: {
        width: { ideal: 1280 }, // High resolution width
        height: { ideal: 720 }, // High resolution height
        facingMode: 'environment',
      },
    })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch(() => {
        onClose();
      });
  };

  const handleError = (errorMessage) => {
    setError(errorMessage); // Write the error message to the error area
    startCamera();
  };

  useEffect(() => {
    if (open) {
      setError(null);
      if (videoRef.current) {
        // Add webkit-playsinline attribute for iOS WebView
        videoRef.current.setAttribute('webkit-playsinline', 'true');
      }
      startCamera();
    } else if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }

    return () => {
      if (videoRef.current?.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, [open]);

  const handleCapture = () => {
    setError(null);
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      const { videoWidth, videoHeight } = videoRef.current;

      // Determine the size of the square based on the smallest dimension
      const squareSize = Math.min(videoWidth, videoHeight);

      // Center the square area
      const sourceX = (videoWidth - squareSize) / 2;
      const sourceY = (videoHeight - squareSize) / 2;

      // Set canvas size to the square size
      canvas.width = squareSize;
      canvas.height = squareSize;

      // Draw the square area from the video feed onto the canvas
      context.drawImage(
        videoRef.current,
        sourceX,
        sourceY,
        squareSize,
        squareSize,
        0,
        0,
        canvas.width,
        canvas.height,
      );

      setIsProcessing(true);
      canvas.toBlob((blob) => {
        if (!blob) {
          handleError('Failed to create image');
          return;
        }

        setCapturedImage(canvas.toDataURL('image/png')); // Save captured image

        // Send the image blob to the backend for recognition
        fetch(`/api/devices/recognize?field=${field.toUpperCase()}`, {
          method: 'POST',
          headers: { 'Content-Type': 'image/png' },
          body: blob,
        })
          .then((response) => {
            if (!response.ok) {
              handleError('Error processing image:');
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json(); // Parse JSON response
          })
          .then((data) => {
            if (data.status === 'success') {
              // Handle successful response
              const value = data.field || 'Unknown';
              // const confidence = data.confidence || 0;
              // console.log(`Data: ${value}, Confidence: ${confidence}`);
              // Pass extracted information to parent component
              onCapture(field, value);
              onClose(); // Close the dialog after processing
            } else if (data.status === 'error') {
              // Handle error response
              const message = data.message || 'An unknown error occurred';
              handleError(message);
            }
          })
          .catch(() => {
            handleError('Error processing image:');
          });
      }, 'image/png');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {field === 'imei' ? t('deviceIMEI') : field === 'vin' ? t('vehicleVIN') : ''}
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', right: 10, top: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {capturedImage ? (
          <img
            src={capturedImage}
            alt="Captured Preview"
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'cover', // Ensure the image covers the area without borders
              backgroundColor: 'black',
              borderRadius: '8px',
            }}
          />
        ) : (
          <div
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '400px',
              aspectRatio: '1 / 1',
              backgroundColor: 'black',
              overflow: 'hidden',
              borderRadius: '8px',
            }}
          >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              ref={videoRef}
              autoPlay
              playsInline
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover', // Ensures the video fits perfectly
              }}
            />
          </div>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <div
          style={{
            marginTop: '16px', // Ensure spacing between the button and the video/image view
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isProcessing ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleCapture}
            >
              Capture Image
            </Button>
          )}
        </div>
        {error && (
          <Typography color="error" style={{ marginTop: 16 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CameraDialog;
